import {
  Row,
  Col,
  Navbar,
  NavDropdown,
  Container,
  Nav,
  Form,
} from "react-bootstrap";
import React, { useState } from "react";
import { Link } from "gatsby";

import jsonData from "./Contents/HomePageContent.en.json";

import "../layouts/styleGlobal.css";
import GeLogo from "../images/Logo/geLogo300x139.png";
import enIcon from "../images/Language/en.png";
import deIcon from "../images/Language/de.png";
import trIcon from "../images/Language/tr.png";

function NavBar({ setLanguage, userLanguage }) {
  const handleEngSelect = async (e) => {
    e.preventDefault();
    setLanguage("en");
    localStorage.setItem("lang", "en");
  };
  const handleDeSelect = async (e) => {
    e.preventDefault();
    setLanguage("de");
    localStorage.setItem("lang", "de");
  };
  const handleTrSelect = async (e) => {
    e.preventDefault();
    setLanguage("tr");
    localStorage.setItem("lang", "tr");
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="dark"
      sticky="top"
      className="navbar-modified"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mb-0 mx-auto">
          <Link
            to="/#portfolio"
            className="mx-3 mb-2 d-flex align-items-end navbar-modified"
          >
            {userLanguage === "en"
              ? jsonData.engNav.about
              : userLanguage === "de"
              ? jsonData.deNav.about
              : jsonData.trNav.about}
          </Link>
          <NavDropdown
            menuVariant="dark"
            title={
              userLanguage === "en"
                ? jsonData.engNav.services
                : userLanguage === "de"
                ? jsonData.deNav.services
                : jsonData.trNav.services
            }
            href="/#service"
            id="collasible-nav-dropdown"
            className="navbar-nounderline mx-3 d-flex align-items-end navbar-modified"
          >
            <Link
              to="/Marriage"
              className="mx-3 mb-2 d-flex align-items-end dropdown-modified"
            >
              {userLanguage === "en"
                ? jsonData.engNav.marrigae
                : userLanguage === "de"
                ? jsonData.deNav.marrigae
                : jsonData.trNav.marrigae}
            </Link>
            <Link
              to="/Corporate-Events"
              className="mx-3 mb-2 d-flex align-items-end dropdown-modified"
            >
              {userLanguage === "en"
                ? jsonData.engNav.corporate
                : userLanguage === "de"
                ? jsonData.deNav.corporate
                : jsonData.trNav.corporate}
            </Link>
            <Link
              to="/Celebrate-Privately"
              className="mx-3 mb-2 d-flex align-items-end dropdown-modified"
            >
              {userLanguage === "en"
                ? jsonData.engNav.private
                : userLanguage === "de"
                ? jsonData.deNav.private
                : jsonData.trNav.private}
            </Link>
          </NavDropdown>

          <Link
            to="/"
            className="mx-3 mb-2 d-flex align-items-end navbar-modified"
          >
            <Navbar.Brand style={{ paddingTop: "0" }}>
              <img
                src={GeLogo}
                width="100"
                height="48"
                className="d-inline-block align-top mx-3 "
                alt="Golden Event Logo"
              />
            </Navbar.Brand>
          </Link>
          {/* <Link
            to="/Gallery"
            className="mx-3 mb-2 d-flex align-items-end navbar-modified"
          >
            {userLanguage === "en"
              ? jsonData.engNav.gallery
              : userLanguage === "de"
              ? jsonData.deNav.gallery
              : jsonData.trNav.gallery}
          </Link> */}

          <NavDropdown
            menuVariant="dark"
            title={
              userLanguage === "en"
                ? jsonData.engNav.gallery
                : userLanguage === "de"
                ? jsonData.deNav.gallery
                : jsonData.trNav.gallery
            }
            href="#"
            id="collasible-nav-dropdown"
            className="navbar-nounderline mx-3 d-flex align-items-end navbar-modified"
          >
            <Link
              to="/Gallery-Altinsaray"
              className="mx-3 mb-2 d-flex align-items-end dropdown-modified"
            >
              {userLanguage === "en"
                ? jsonData.engNav.galleryAltinsaray
                : userLanguage === "de"
                ? jsonData.deNav.galleryAltinsaray
                : jsonData.trNav.galleryAltinsaray}
            </Link>
            <Link
              to="/Gallery-Gumussaray"
              className="mx-3 mb-2 d-flex align-items-end dropdown-modified"
            >
              {userLanguage === "en"
                ? jsonData.engNav.galleryGumussaray
                : userLanguage === "de"
                ? jsonData.deNav.galleryGumussaray
                : jsonData.trNav.galleryGumussaray}
            </Link>
          </NavDropdown>

          <Link
            to="/#footer"
            className="mx-3 mb-2 d-flex align-items-end navbar-modified"
          >
            {userLanguage === "en"
              ? jsonData.engNav.contact
              : userLanguage === "de"
              ? jsonData.deNav.contact
              : jsonData.trNav.contact}
          </Link>
        </Nav>

        <Nav>
          <Nav.Link onClick={handleEngSelect}>
            <img
              src={enIcon}
              width={userLanguage === "en" ? 24 : 20}
              height={userLanguage === "en" ? 24 : 20}
              className="d-inline-block align-top mx-0 pb-0"
              alt="Golden Event Logo"
            />
          </Nav.Link>
          <Nav.Link onClick={handleDeSelect}>
            <img
              src={deIcon}
              width={userLanguage === "de" ? 24 : 20}
              height={userLanguage === "de" ? 24 : 20}
              className="d-inline-block align-top mx-0 pb-0"
              alt="Golden Event Logo"
            />
          </Nav.Link>
          <Nav.Link onClick={handleTrSelect}>
            <img
              src={trIcon}
              width={userLanguage === "tr" ? 24 : 20}
              height={userLanguage === "tr" ? 24 : 20}
              className="d-inline-block align-top mx-0 pb-0"
              alt="Golden Event Logo"
            />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
