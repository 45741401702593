import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  InputGroup,
  Button,
  Alert
} from "react-bootstrap";
import {
  Phone,
  GeoAltFill,
  TelephoneFill,
  Envelope,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from "react-bootstrap-icons";

import AltinsarayEvent from "../images/Logo/AltinsarayEvent.png";
import geLogo from "../images/Logo/geLogo300x139.png";
import gumussarayLogo from "../images/Logo/gumussarayLogo.png";

function Footer() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    fetch("https://www.goldenevent-berlin.de/api/contact/index.php", {
      mode: "no-cors",
      method: "POST",
      body: JSON.stringify({
        name: clientName,
        email: clientEmail,
        phone: clientPhone,
        message: clientMessage,
      }),
    });
    setShowAlert(true);
  };

  const [clientEmail, setClientEmail] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        // dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Thanks for contacting us!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Someone from the team will contact you. </p>
          <p>You may call us @+49 163 422 94 46</p>
        </Modal.Body>
      </Modal> */}

      <div id="footer">
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="row-style justify-content-md-center">
            <Col md={4} className="footer-desc-address">
              <h6>Golden Events and Marketing Ltd.</h6>

              <Row className="row-style-address">
                <Col xs={2}>
                  <div className="icon-box address-icon">
                    <GeoAltFill color="#1a1718" size={20} />
                  </div>
                </Col>
                <Col
                  xs={10}
                  style={{ marginTop: "4px" }}
                  className="address-txt"
                >
                  <p>Drontheimer Strasse 32, 13359 Berlin.</p>
                </Col>
              </Row>

              <Row className="row-style-address">
                <Col xs={2}>
                  <div className="icon-box address-icon">
                    <Phone color="#1a1718" size={20} />
                  </div>
                </Col>
                <Col
                  xs={10}
                  style={{ marginTop: "4px" }}
                  className="address-txt"
                >
                  <p>+49 163 422 94 46</p>
                </Col>
              </Row>

              <Row className="row-style-address">
                <Col xs={2}>
                  <div className="icon-box address-icon">
                    <TelephoneFill color="#1a1718" size={20} />
                  </div>
                </Col>
                <Col
                  xs={10}
                  style={{ marginTop: "4px" }}
                  className="address-txt"
                >
                  <p>+49 30 346 22 645</p>
                </Col>
              </Row>

              <Row className="row-style-address">
                <Col xs={2}>
                  <div className="icon-box address-icon">
                    <Envelope color="#1a1718" size={20} />
                  </div>
                </Col>
                <Col
                  xs={10}
                  style={{ marginTop: "4px" }}
                  className="address-txt"
                >
                  <p>info@goldenevent-berlin.de</p>
                </Col>
              </Row>

              <Row>
                <Col
                  xs={12}
                  style={{ marginTop: "4px" }}
                  className="mb-4 address-txt"
                >
                  <a href="https://www.facebook.com/people/Goldeneventberlin/100063722072802/" target='_blank'>
                    <Facebook className="mx-2" color="#a1a1a1" size={28} />
                  </a>
                  <a href="https://www.instagram.com/goldeneventberlin/?hl=de" target='_blank'>
                    <Instagram className="mx-2" color="#a1a1a1" size={28} />
                  </a>
                  <a href="https://twitter.com/hh_goldenevent?lang=de" target='_blank'>
                    <Twitter className="mx-2" color="#a1a1a1" size={28} />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={4} className="footer-desc">
              <img
                src={geLogo}
                width="50%"
                //   height="100%"
                className="d-inline-block mb-2"
                style={{ marginTop: "-10px" }}
                alt="Golden Event Logo"
              />
              <img
                src={AltinsarayEvent}
                width="40%"
                //   height="100%"
                className="d-inline-block my-2"
                alt="Altinasary Event Logo"
              />
              <img
                src={gumussarayLogo}
                width="40%"
                //   height="100%"
                className="d-inline-block my-2"
                alt="Gumussary Logo"
              />
            </Col>
            <Col md={4} className="">

              {showAlert ? 
              (
                  <>
                  <p>THANKS FOR CONTACTING US!</p>
                  <p>Someone from the team will contact you.</p><p>You may call us @+49 163 422 94 46</p><hr />
                    <Button
                      onClick={() => setShowAlert(false)}
                      variant="outline-success"
                    >
                      Close
                    </Button>
                  </>
              ) : (
              <div style={{ alignItems: "flex-end" }}>
                <p style={{ textAlign: "right" }} className="mt-4">LET'S KEEP IN TOUCH</p>

                <Form action="#" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Present Address</Form.Label> */}
                    <Form.Control
                      required
                      as="textarea"
                      rows={3}
                      name="message"
                      id="message"
                      placeholder="Message*"
                      // onChange={(e) => console.log(e.target.value)}
                      onChange={(e) => setClientMessage(e.target.value)}
                    />
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name*"
                        onChange={(e) => setClientName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Control
                        required
                        type="text"
                        maxLength="17"
                        name="phone"
                        id="phone"
                        placeholder="Phone*"
                        onChange={(e) => setClientPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Row>

                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <Envelope color="#a1a1a1" size={20} />
                    </InputGroup.Text>
                    <FormControl
                      required
                      name="email"
                      id="email"
                      placeholder="Your Email ID*"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setClientEmail(e.target.value)}
                    />
                    <Button
                      type="submit"
                      variant="secondary"
                      style={{ backgroundColor: "#4d4d4d" }}
                    >
                      <div
                        style={{
                          fontFamily: "Futura-LT-W01-Light",
                          fontWeight: "bold",
                          letterSpacing: "0.15em",
                          color: "#ececec",
                        }}
                      >
                        Send
                      </div>
                    </Button>
                  </InputGroup>
                </Form>
              </div>)}

            </Col>
          </Row>
        </Container>
      </div>

      <div id="copyright">
        &copy; 2022 by Golden Events and Marketing GmbH. Developed by{" "}
        <a style={{ color: "#757575" }} href="https://www.bdlancelimited.com">
          BDLance
        </a>
      </div>
    </>
  );
}

export default Footer;
